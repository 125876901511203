<template>
  <div class="cursor-pointer" @click="goToExercise">
    <div v-if="metadata.changes_requested" class="text-xs text-primary">
      שקלו לתת לתרגיל הזדמנות נוספת. כדי לעשות את התרגיל מחדש לחצו כאן >>
    </div>
    <div v-else class="text-xs text-primary">
      כדי לראות את התרגיל שלכם לחצו כאן >>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import { useRouter } from 'vue-router';
import { INotificationMetadata } from '/@/types/interfaces';

const props = defineProps({
  metadata: {
    type: Object as PropType<INotificationMetadata>,
    required: true,
  },
});

const router = useRouter();

const goToExercise = () => {
  router.push({
    name: 'skill-lesson',
    params: {
      course: props.metadata.course,
      lesson: props.metadata.lesson,
    },
    query: { page: props.metadata.page },
  });
};
</script>
